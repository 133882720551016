import SendIcon from '@mui/icons-material/Send';
import { Box, Button, TextField } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';

const dotFlashing = keyframes`
  0% {
    background-color: #ff006a;
  }
  50%, 100% {
    background-color: rgba(255, 0, 106, 0.3);
  }
`;

export const StyledSendIcon = styled(SendIcon)({
  color: '#6598cb',
});

export const StyledChatBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen',
})(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundImage:
    'linear-gradient(to bottom, #6598cb, #7eaad7, #96bce3, #aecef0, #c6e1fc)',
  textAlign: 'center',
  borderRadius: '10px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: 6,
}));

export const StyledIcon = styled('img')({
  width: '25px',
  height: '25px',
});

export const StyledButton = styled(Button)({
  minWidth: '0',
  display: 'block',
  height: '20px',
  marginLeft: 'auto',
  padding: 0,
  cursor: 'pointer',
  '& .MuiButton-startIcon': {
    margin: 0,
    color: 'white',
  },
});

export const DividedLine = styled('div')({
  width: 'calc(100% + 12px)',
  borderBottom: '1px solid white',
  marginLeft: '-6px',
});

export const ChatFieldBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen' && prop !== 'isActive',
})(({ isActive, isFullScreen }) => ({
  backgroundColor: 'white',
  borderRadius: '10px',
  minHeight: isFullScreen ? 'unset' : '150px',
  maxHeight: isFullScreen ? '100%' : '230px',
  marginBottom: '20px',
  color: 'grey',
  display: 'flex',
  justifyContent: isActive ? 'center' : 'unset',
  flexDirection: 'column',
  alignItems: 'left',
  textAlign: 'center',
  overflow: 'auto',
  fontSize: isFullScreen ? '14px' : '11px',
  height: '100%',
}));

export const MessageBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isUser',
})(({ isUser }) => ({
  borderRadius: '10px',
  padding: '8px 10px',
  width: 'max-content',
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  backgroundColor: isUser ? 'black' : '#BABABA',
  color: isUser ? 'white' : 'black',
  boxShadow: '0 0 5px blue',
  marginBottom: '10px',
  marginRight: isUser && '10px',
  maxWidth: isUser ? 'calc(100% - 30px)' : '100%',
  textAlign: 'left',
  overflowX: 'clip',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  '&:first-of-type': {
    marginTop: '10px',
  },
  '& p': {
    margin: 0,
  },
}));

export const ContentBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen',
})(({ isFullScreen }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 'max-content',
  margin: '0 10px 10px',
  width: isFullScreen ? 'calc(100% - 90px)' : 'calc(100% - 30px)',
  alignItems: 'flex-end',
}));

export const StyledSaveButton = styled(Button)({
  color: '#6598cb',
  padding: 0,
  display: 'flex',
  alignSelf: 'flex-end',
  fontSize: '10px',
});

export const LoadingProgress = styled('div')({
  zIndex: '9999',
  position: 'absolute',
  right: '50%',
  bottom: '70px',
  transform: 'translate(-50%, -50%)',
  width: '10px',
  height: '10px',
  borderRadius: '5px',
  backgroundColor: '#ff006a',
  color: '#ff006a',
  animation: `${dotFlashing} 1s infinite linear alternate`,
  animationDelay: '0.5s',
  '&::before, &::after': {
    content: "''",
    display: 'inline-block',
    position: 'absolute',
    top: '0',
  },
  '&::before': {
    left: '-15px',
    width: '10px',
    height: '10px',
    borderRadius: '5px',
    backgroundColor: '#ff006a',
    color: '#ff006a',
    animation: `${dotFlashing} 1s infinite alternate`,
    animationDelay: '0s',
  },
  '&::after': {
    left: '15px',
    width: '10px',
    height: '10px',
    borderRadius: '5px',
    backgroundColor: '#ff006a',
    color: '#ff006a',
    animation: `${dotFlashing} 1s infinite alternate`,
    animationDelay: '1s',
  },
});

export const StyledQuestionField = styled(TextField)({
  backgroundColor: 'white',
  borderRadius: '10px',
  marginBottom: '15px',
  width: '100%',
  '& .MuiInputBase-input': {
    paddingTop: '6px',
    paddingBottom: '6px',
    color: 'black',
    '&::placeholder': {
      opacity: 1,
      color: 'grey',
      fontSize: '13px',
    },
  },
  '&::placeholder': {
    color: 'grey',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

export const StyledActionBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen',
})(({ isFullScreen }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: isFullScreen ? 'row-reverse' : 'column',
  maxWidth: isFullScreen ? '600px' : '100%',
  minWidth: isFullScreen ? '300px' : 'unset',
  alignSelf: 'center',
  width: '100%',
}));

export const StyledResetButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen',
})(({ isFullScreen }) => ({
  borderRadius: '10px',
  maxWidth: '140px',
  margin: isFullScreen ? '' : '0 auto',
  marginBottom: isFullScreen ? '0' : '15px',
  borderColor: '#6598cb',
  color: '#6598cb',
}));

export const ChatForm = styled('form')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});
