import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const VerifiedContent = () => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography
        variant="h4"
        component="h2"
        fontWeight="600"
        color={theme.palette.primary.main}
      >
        Welcome...
      </Typography>
      <Typography
        variant="p"
        component="h3"
        fontWeight="600"
        color={theme.palette.primary.main}
      >
        to your personal workspace.
      </Typography>
      <Typography variant="p" color={theme.palette.primary.main}>
        Click on the Start here collection
      </Typography>
      <Typography variant="p" color={theme.palette.primary.main}>
        Thank you for trying Picker. <br /> We hope you like and enjoy it
      </Typography>
      <Typography variant="p" color={theme.palette.primary.main}>
        /Tobbe & Magnus
      </Typography>
    </Box>
  );
};

export default VerifiedContent;
