import { useEffect, useState } from 'react';

export const useTimer = (timeout) => {
  const [countDown, setCountDown] = useState(0);

  const init = () => {
    const currentTime = Math.round(new Date().getTime() / 1000);
    localStorage.setItem('unblockResend', currentTime + timeout);
    setCountDown(timeout);
  };

  useEffect(() => {
    const ublockTime = localStorage.getItem('unblockResend');
    if (ublockTime) {
      const currentTime = Math.round(new Date().getTime() / 1000);
      setCountDown(ublockTime - currentTime);
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countDown > 1) {
        const currentTime = Math.round(new Date().getTime() / 1000);
        setCountDown(localStorage.getItem('unblockResend') - currentTime);
      } else {
        setCountDown(0);
        localStorage.removeItem('unblockResend');
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [countDown]);

  return { countDown, init };
};
