import { useEffect, useRef } from 'react';
import { PATHS } from 'AppPaths';
import { CHECK_SUBCRIBE_INTERVAL } from 'consts';
import { useAtom } from 'jotai';
import { subInfoAtom } from 'store';
import { history } from 'util/router';
import { getSubscriptionStatus, setCachedSubData } from 'util/subscription';

const useCheckSub = (auth) => {
  const [, setSubInfo] = useAtom(subInfoAtom);
  const interval = useRef(null);

  useEffect(() => {
    if (auth && auth.emailVerified) {
      const setNewStatus = async () => {
        const data = await setCachedSubData();
        setSubInfo(data);
        return data;
      };
      (async () => {
        // For the first instant call
        const newStatus = await setNewStatus();

        if (newStatus?.isTrialExpires && !newStatus?.status) {
          history.replace(PATHS.PAYWALL);
        }

        new Promise((resolve) => {
          interval.current = setInterval(() => {
            if (auth) {
              getSubscriptionStatus().then((data) => {
                setSubInfo(data);
                resolve(data);
              });
            } else {
              clearInterval(interval.current);
              setSubInfo(null);
            }
          }, CHECK_SUBCRIBE_INTERVAL);
        });
      })();
    } else {
      setSubInfo(null);
      clearInterval(interval.current);
    }

    // Unsubscribe on cleanup
    return () => {
      setSubInfo(null);
      clearInterval(interval.current);
    };
  }, [auth]);
};

export default useCheckSub;
