import { Box, Typography } from '@mui/material';
import { Link } from 'util/router';

import {
  AcceptButton,
  BannerContainer,
  //   SettingsButton,
  StyledLink,
} from './styles';

const CustomCookieBanner = ({ onAccept }) => {
  return (
    <BannerContainer>
      <Box maxWidth="440px" mx="auto" px={2}>
        <Typography variant="h5" fontSize="14px" fontWeight="600">
          We Care About Your Privacy
        </Typography>
        <Box my="15px">
          <Typography variant="string" color="grey" fontSize="14px">
            Our website utilizes cookies to enhance your browsing experience,
            maintain session information, and gather statistical data on user
            navigation. For detailed information, please refer to our&nbsp;
            <StyledLink component={Link} to="/cookie-policy">
              Cookie Policy
            </StyledLink>
            .
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <AcceptButton onClick={onAccept}>ACCEPT</AcceptButton>
          {/* <SettingsButton>COOKIE SETTINGS</SettingsButton> */}
        </Box>
      </Box>
    </BannerContainer>
  );
};

export default CustomCookieBanner;
