import { useEffect, useRef } from 'react';
import { getAuth, signOut } from 'firebase/auth';

import { deleteCookie } from './cookies';
import { firebaseApp } from './firebase';

const auth = getAuth(firebaseApp);

// Make an API request to `/api/{path}`
export async function apiRequest(path, method = 'GET', data) {
  const accessToken = auth.currentUser
    ? await auth.currentUser.getIdToken()
    : undefined;

  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 'error') {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === 'auth/invalid-user-token') {
          signOut(auth);
          localStorage.clear();
          deleteCookie('auth_token');
        }

        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

// Hook that returns previous value of state
export function usePrevious(state) {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}

export function getShareUrl() {
  const { protocol, hostname, port } = window.location;
  const hostParts = hostname.split('.');

  let newHost;

  if (hostname === 'localhost') {
    newHost = hostname;
  } else if (hostParts.length === 2) {
    // If there is no subdomain (e.g., "mydomain.com"), add "share" as a subdomain
    newHost = `${process.env.REACT_APP_SHARE_SUBDOMAIN}.${hostname}`;
  } else {
    // If there is a subdomain, replace the first part with "share"
    newHost = [
      process.env.REACT_APP_SHARE_SUBDOMAIN,
      ...hostParts.slice(1),
    ].join('.');
  }

  return `${protocol}//${newHost}${port ? `:${port}` : ''}`;
}
