import React from 'react';

function SvgOrganize01W(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.74 45.2" {...props}>
      <style>{'.organize_01_w_svg__st2{fill:#fff}'}</style>
      <g id="organize_01_w_svg__Layer_2">
        <path
          d="M18.43 33.62l-7.17-15.37s-.06-1.29 1.79-2.16c1.85-.86 2.52.07 2.52.07l5.77 12.38-7.11-15.25s.12-.91 1.68-1.72c1.57-.81 2.4-.34 2.4-.34l1.01 2.17 6.04 12.95-6.03-12.95s.43-.74 1.71-1.34c1.28-.59 2.2-.95 2.54-.72.34.23 6.07 13.01 6.07 13.01l-4.11-9.03s.57-.73 1.46-1.15c.89-.42 1.22-.72 1.94-.67l.72.05 8.18 17.54s1.84 5.12-2.35 8.78-7.85 3.82-7.85 3.82l-4.68-.38s-3.46-1.26-4.6-2.36c-1.14-1.1-5.3-5.53-5.3-5.53L10.7 33.2s-1.01-1.33-.43-1.91l.34-.94s.81-.92 1.91-.58c1.09.34 2.97.87 2.97.87l1.73 1.21 1.21 1.77z"
          fill="none"
          stroke="#fff"
          strokeWidth={3}
          strokeMiterlimit={10}
        />
        <path
          className="organize_01_w_svg__st2"
          d="M0 0h8.11v8.11H0zM12.46 0h8.11v8.11h-8.11zM24.93 0h8.11v8.11h-8.11zM0 11.08h8.11v8.11H0zM0 22.17h8.11v8.11H0z"
        />
      </g>
    </svg>
  );
}

export default SvgOrganize01W;
