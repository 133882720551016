import { styled } from '@mui/material/styles';

const DrawerHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'height',
})(({ theme, height }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  minHeight: height,
  justifyContent: 'flex-end',
}));

export default DrawerHeader;
