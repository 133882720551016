import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { tryForFreeEmailAtom } from 'store';

import { StyledLink, StyledTypography, TextButton } from '../styles';

const ErrorVerifiedContent = ({
  handleResendEmail,
  handleClose,
  error,
  pending,
}) => {
  const theme = useTheme();

  const [tryForFreeEmail] = useAtom(tryForFreeEmailAtom);

  return (
    <>
      <Typography color={theme.palette.primary} variant="h4">
        Sorry...
      </Typography>
      <Typography>
        but we have not been able to verify your email address.
      </Typography>
      <StyledTypography variant="h6" component="p">
        Please open the email we sent to{' '}
        <StyledLink href={`mailto:${tryForFreeEmail}`}>
          {tryForFreeEmail}
        </StyledLink>
      </StyledTypography>
      <Box textAlign="center">
        <TextButton onClick={handleResendEmail}>
          {pending ? (
            <CircularProgress color="success" size={26} />
          ) : (
            'Resend email verification'
          )}
        </TextButton>
        <Typography my={1}>OR</Typography>
        <TextButton onClick={handleClose}>Go back to sign-up</TextButton>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
    </>
  );
};

export default ErrorVerifiedContent;
