/* eslint-disable prettier/prettier */
import { Helmet } from 'react-helmet';

const Rewardful = () => {

  return (
    <Helmet>
      <script strategy="afterInteractive">
        {`
          (function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');
        `}
      </script>
      <script async src='https://r.wdfl.co/rw.js' data-rewardful={process.env.REACT_APP_REWARDFUL_ID}></script>
    </Helmet>
  );
};

export default Rewardful;