import { CHECK_SUBSCRIBE_EXPIRATION } from 'consts';
import { getRCCustomer } from 'util/firebasefunctions';

const getCachedSubData = () => {
  const data = JSON.parse(localStorage.getItem('subscribe'));
  if (!data) return null;

  const {
    trialExpires,
    premiumExpires,
    expire,
    currentSub,
    premiumPlan,
    remainingTrialTime,
  } = data;
  const now = Date.now();

  // Clear storage if the period has expired
  if (now > expire) {
    localStorage.removeItem('subscribe');
    return null;
  }

  const status = trialExpires > now || premiumExpires > now;
  return {
    isTrialExpires: now > trialExpires,
    isPremiumExpires: now > premiumExpires,
    status,
    currentSub,
    premiumPlan,
    remainingTrialTime,
    isSubOrTrial: status,
  };
};

export const setCachedSubData = async () => {
  try {
    const rcCustomer = await getRCCustomer();

    const premiumPurchase = Date.parse(
      rcCustomer.data.subscriber.entitlements?.premium?.purchase_date
    );

    const trialExpires = Date.parse(
      rcCustomer.data.subscriber.entitlements?.trial.expires_date
    );

    let premiumExpires = Date.parse(
      rcCustomer.data.subscriber.entitlements?.premium?.expires_date
    );

    let premiumPlan = 0;
    let duration = '';

    if (isNaN(premiumExpires)) {
      premiumExpires = null;
    }

    // Duration of subscription in days
    if (premiumExpires) {
      premiumPlan = (premiumExpires - premiumPurchase) / 1000 / 60 / 60 / 24;
    }

    // Type of subcription
    if (premiumPlan !== 0) {
      duration = premiumPlan > 31 ? 'Yearly' : 'Monthly';
    }

    const now = Date.now();

    // Subscription identifier
    const identifier =
      rcCustomer.data.subscriber.entitlements?.premium?.product_identifier;

    // Current subscription info
    const currentSub =
      now < premiumExpires
        ? rcCustomer.data.subscriber.subscriptions[identifier]
        : null;

    // Remaining time of the trial period
    const remainingMs = trialExpires - now;
    let remainingTrialTime = 0;
    if (remainingMs > 0) {
      remainingTrialTime = Math.floor(remainingMs / 1000 / 60 / 60 / 24);
    }

    localStorage.setItem(
      'subscribe',
      JSON.stringify({
        trialExpires,
        premiumExpires,
        currentSub,
        remainingTrialTime,
        expire: now + CHECK_SUBSCRIBE_EXPIRATION,
        premiumPlan: duration,
      })
    );

    const status = trialExpires > now || premiumExpires > now;
    return {
      isTrialExpires: now > trialExpires,
      isPremiumExpires: now > premiumExpires,
      status,
      currentSub,
      premiumPlan: duration,
      remainingTrialTime,
      isSubOrTrial: status,
    };
  } catch (e) {
    console.warn(e);
  }
};

export const getSubscriptionStatus = async () => {
  const cachedResult = getCachedSubData();

  if (cachedResult !== null) return cachedResult;

  return await setCachedSubData();
};

export const getPrice = (productPrice) => {
  if (!productPrice) return null;

  const price = productPrice.unit_amount
    ? productPrice.unit_amount / 100
    : productPrice.unit_amount_decimal / 100;

  return price;
};
