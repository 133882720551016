import { Box, Button, Link as LinkMui, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  width: '400px',
  maxHeight: '100%',
  backgroundColor: theme.palette.modalsBackground,
  border: '1px solid black',
  boxShadow: 24,
  padding: '50px',
  gap: 20,

  [theme.breakpoints.down('sm')]: {
    width: '360px',
  },
}));

export const StyledButton = styled(Button)(({ width }) => ({
  width: width,
}));

export const TextButton = styled(Button)(({ theme, width }) => ({
  width: width,
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  padding: '0',
}));

export const GreyTextButton = styled(Button)({
  fontSize: 'inherit',
  color: 'grey',
  padding: 0,
  display: 'inline',
  verticalAlign: 'baseline',
  textDecoration: 'underline',
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const StyledLink = styled(LinkMui, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'textDecoration',
})(({ color, textDecoration }) => ({
  color: color,
  textDecoration,
  textUnderlineOffset: '6px',
}));
