import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { tryForFreeEmailAtom } from 'store';

import {
  GreyTextButton,
  StyledButton,
  StyledLink,
  StyledTypography,
} from '../styles';

const NotVerifiedContent = ({
  handleResendEmail,
  handleConfirm,
  error,
  pending,
}) => {
  const [tryForFreeEmail] = useAtom(tryForFreeEmailAtom);

  const theme = useTheme();

  return (
    <>
      <Typography
        variant="h5"
        component="h2"
        fontWeight="600"
        color={theme.palette.primary.main}
      >
        Verify your email…
      </Typography>
      <StyledTypography variant="h6" component="p">
        We just need to verify your email address.
      </StyledTypography>
      <StyledTypography variant="h6" component="p">
        Please open the email we sent to{' '}
        <StyledLink href={`mailto:${tryForFreeEmail}`}>
          {tryForFreeEmail}
        </StyledLink>
      </StyledTypography>
      <StyledTypography variant="h6" component="p">
        Don’t forget to check your Spam folder.
      </StyledTypography>
      <Typography color="textSecondary" variant="h6" component="p">
        If you have not recieved an email{' '}
        <GreyTextButton onClick={handleResendEmail}>
          {pending ? (
            <CircularProgress color="success" size={26} />
          ) : (
            'click here'
          )}
        </GreyTextButton>{' '}
        to send it again
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Box display="flex" width="max-content" margin="0 auto" gap="30px">
        <StyledButton variant="contained" onClick={handleConfirm}>
          Continue
        </StyledButton>
      </Box>
    </>
  );
};

export default NotVerifiedContent;
