import { useState } from 'react';
import { Modal } from '@mui/material';
import { PATHS } from 'AppPaths';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { useAuth } from 'util/auth';
import { firebaseApp } from 'util/firebase';
import { useRouter } from 'util/router';

import ErrorVerifiedContent from './ErrorVerifiedContent';
import NotVerifiedContent from './NotVerifiedContent';
import { StyledModalContent } from './styles';
import VerifiedContent from './VerifiedContent';

const auth = getAuth(firebaseApp);

const VerificationModal = ({ open, handleClose }) => {
  const router = useRouter();

  const contextAuth = useAuth();

  const [isClicked, setIsClicked] = useState(false);
  const [error, setError] = useState('');
  const [pending, setPending] = useState(false);

  const handleConfirm = async () => {
    await contextAuth.handleReload();
    if (auth.currentUser.emailVerified) {
      const pathName = router.pathname;
      const lastChart = pathName.slice(-1);
      const currentPathname =
        lastChart === '/' ? pathName.slice(0, -1) : pathName;
      if (currentPathname !== PATHS.REFERRAL) {
        router.push(PATHS.COLLECTIONS);
      } else {
        handleClose();
      }
    } else {
      setIsClicked(true);
    }
  };

  const handleResendEmail = async () => {
    setPending(true);
    try {
      await sendEmailVerification(auth.currentUser);
      setError('');
    } catch (e) {
      setError(e.message);
    } finally {
      setPending(false);
    }
  };

  const onModalClose = async () => {
    if (!auth.currentUser.emailVerified) {
      await contextAuth.handleReload();
    }

    handleClose();

    if (
      router.pathname !== PATHS.COLLECTIONS &&
      router.pathname !== PATHS.REFERRAL
    ) {
      router.push(PATHS.COLLECTIONS);
    }
  };

  return (
    <Modal open={open} onClose={onModalClose}>
      <StyledModalContent>
        {auth.currentUser && auth.currentUser.emailVerified ? (
          <VerifiedContent />
        ) : isClicked ? (
          <ErrorVerifiedContent
            handleResendEmail={handleResendEmail}
            handleClose={handleClose}
            error={error}
            pending={pending}
          />
        ) : (
          <NotVerifiedContent
            handleResendEmail={handleResendEmail}
            handleConfirm={handleConfirm}
            error={error}
            pending={pending}
          />
        )}
      </StyledModalContent>
    </Modal>
  );
};

export default VerificationModal;
