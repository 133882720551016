import { useRef, useState } from 'react';
import { Box, Modal } from '@mui/material';
import { PATHS } from 'AppPaths';
import { getAuth } from 'firebase/auth';
import { useAtom } from 'jotai';
import { subInfoAtom } from 'store';
import { aiBotRequestTrack } from 'util/analyticsHandlers';
import { firebaseApp } from 'util/firebase';
import { useRouter } from 'util/router';

import ChatBotContent from './ChatBotContent';

const auth = getAuth(firebaseApp);

const ChatBot = () => {
  const [subInfo] = useAtom(subInfoAtom);

  const [currentUserMessage, setCurrentUserMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isFullScreen, setFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectCollection, setSelectCollection] = useState('');

  const scrollBoxRef = useRef(null);

  const router = useRouter();

  const processMessageToChatGPT = async (chatMessages) => {
    setIsLoading(true);

    const accessToken = auth.currentUser
      ? await auth.currentUser.getIdToken()
      : undefined;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_PICKER_API_URL}/v2/auth/openai/?format=html`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ data: chatMessages }),
        }
      );
      setIsLoading(false);
      return response.json();
    } catch (e) {
      setIsLoading(false);
      console.warn(e);
    }
  };

  const handleSubmit = async (e) => {
    if (!subInfo || subInfo?.isSubOrTrial) {
      e.preventDefault();

      aiBotRequestTrack();

      if (currentUserMessage !== '') {
        try {
          const updatedMessages = [
            ...messages,
            {
              content: currentUserMessage,
              role: 'user',
            },
          ];

          setMessages(updatedMessages);

          const { content, htmlContent } = await processMessageToChatGPT(
            updatedMessages.map((message) => ({
              role: message.role,
              content: message.content,
            }))
          );

          const askMessage = {
            content: currentUserMessage,
            role: 'user',
          };

          if (content) {
            const answerMessage = {
              content,
              htmlContent,
              role: 'assistant',
              saved: false,
            };
            setMessages([...messages, askMessage, answerMessage]);
          }
          setCurrentUserMessage('');
        } catch (e) {
          console.warn(e);
        }

        scrollBoxRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
      }
    } else {
      router.replace(PATHS.PAYWALL);
    }
  };

  const propsToPass = {
    isFullScreen,
    setFullScreen,
    currentUserMessage,
    setCurrentUserMessage,
    messages,
    handleSendRequest: handleSubmit,
    isLoading,
    setIsError,
    isError,
    handleCloseError: () => setIsError(false),
    setMessages,
    scrollBoxRef,
    setSelectCollection,
    selectCollection,
  };

  return !isFullScreen ? (
    <Box width="96%" mx="auto">
      <ChatBotContent {...propsToPass} />
    </Box>
  ) : (
    <Modal open={isFullScreen} onClose={() => setFullScreen(false)}>
      <ChatBotContent {...propsToPass} />
    </Modal>
  );
};

export default ChatBot;
