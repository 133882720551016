import { StyledButton } from './styles';

const CustomButton = (props) => {
  const {
    margin = '24px auto 0',
    width = '200px',
    fontSize = 18,
    bgColor,
    color,
    bgHoverColor,
    children,
    height,
    ...otherProps
  } = props;
  return (
    <StyledButton
      bgColor={bgColor}
      bgHoverColor={bgHoverColor}
      color={color}
      width={width}
      height={height}
      margin={margin}
      fontSize={fontSize}
      {...otherProps}
    >
      {children}
    </StyledButton>
  );
};

export default CustomButton;
