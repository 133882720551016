import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PATHS } from 'AppPaths';
import { useAtom } from 'jotai';
import Banner from 'layout/Banner';
import LoginModal from 'layout/Navbar/LoginModal';
import WaitingListModal from 'layout/Navbar/WaitingListModal';
import platform from 'platform';
import {
  headerHeightAtom,
  isBannerOpenAtom,
  isWatingModalOpenAtom,
  mapImgAtom,
} from 'store';
import { useAuth } from 'util/auth.js';
import { getNoImg } from 'util/db.js';
import { Link, useRouter } from 'util/router';
import { useDarkMode } from 'util/theme.js';

import {
  AppBar,
  CustomButton,
  CustomGrid,
  CustomLink,
  StyledPickerLogo,
  StyledPickerLogoInverted,
} from './styles';

const links = [
  { path: PATHS.FEATURES, name: 'Features' },
  { path: PATHS.PRICING, name: 'Price' },
  { path: PATHS.COMPANY, name: 'Company' },
  { path: PATHS.SUPPORT, name: 'Help & Support' },
];

const Navbar = ({ open, ToggleDrawer, drawerWidth, setIsSurferOpen }) => {
  const auth = useAuth();

  const router = useRouter();

  const headerRef = useRef(null);

  const [, setMapImg] = useAtom(mapImgAtom);
  const [isWatingModalOpen, setIsWaitingModalOpen] = useAtom(
    isWatingModalOpenAtom
  );
  const [, setHeaderHeight] = useAtom(headerHeightAtom);

  const [isBannerOpen, setIsBannerOpen] = useAtom(isBannerOpenAtom);

  const [menuAccountState, setMenuAccountState] = useState(null);
  const [isSigninOpen, setIsSigninOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const darkMode = useDarkMode();

  const isNotLimitWidth = useMediaQuery((theme) =>
    theme.breakpoints.down('md')
  );

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const openAuthMenu = Boolean(anchorEl);

  const handleOpenAccountMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuAccountState({ anchor: event.currentTarget, id });
  };

  const handleCloseAuthMenu = () => {
    setAnchorEl(null);
  };

  const handleSignInOpen = () => {
    setIsSigninOpen(true);
    handleCloseAuthMenu();
  };

  const handleSignUpOpen = () => {
    setIsSignupOpen(true);
    handleCloseAuthMenu();
  };

  const handleCloseAccountMenu = () => {
    setMenuAccountState(null);
  };

  useEffect(() => {
    //TJ Detta är för att komma till collection om man är inloggad, tyvärr slår den på alla refresher
    //router.push('/collections');
    getNoImg().then((result) => {
      setMapImg(result.images);
    });
  }, []);

  useEffect(() => {
    const isOpen = JSON.parse(localStorage.getItem('isBannerOpen'));
    if (isOpen && auth.user && platform.name === 'Chrome') {
      setIsBannerOpen(true);
    } else {
      setIsBannerOpen(false);
    }
  }, [auth.user, isNotLimitWidth, setIsBannerOpen]);

  useEffect(() => {
    setHeaderHeight(headerRef.current.clientHeight - 2);
  }, [isBannerOpen, isNotLimitWidth, setHeaderHeight]);

  return (
    <>
      <AppBar
        position="fixed"
        color="inherit"
        open={open}
        drawerWidth={drawerWidth}
        ref={headerRef}
      >
        {isBannerOpen && (
          <Banner
            setIsSurferOpen={setIsSurferOpen}
            setIsBannerOpen={setIsBannerOpen}
          />
        )}
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box display="flex" alignItems="center">
            {auth.user && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={ToggleDrawer}
                edge="start"
                sx={{ height: '40px', mr: 2, ...(open && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Link to="/">
              {darkMode.value ? (
                <StyledPickerLogoInverted />
              ) : (
                <StyledPickerLogo />
              )}
            </Link>
          </Box>
          {router.pathname !== PATHS.TRYFORFREE &&
            router.pathname !== PATHS.RECIPES && (
              <>
                {!auth.user && auth.user !== null && (
                  <CustomGrid
                    container
                    item
                    columnSpacing={{ xs: 1, md: 2 }}
                    rowSpacing={1}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {links.map((el, index) => (
                      <Grid
                        key={index}
                        item
                        display={{ md: 'grid', xs: 'none' }}
                      >
                        <CustomLink component={NavLink} to={el.path}>
                          {el.name}
                        </CustomLink>
                      </Grid>
                    ))}
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => router.push(PATHS.DOWNLOAD)}
                      >
                        Download
                      </Button>
                    </Grid>
                  </CustomGrid>
                )}
                <Box display="flex" alignItems="center" gap={{ xs: 1, md: 2 }}>
                  {!auth.user &&
                    auth.user !== null &&
                    (isMobile ? (
                      <>
                        <IconButton
                          color="inherit"
                          aria-label="open auth menu"
                          onClick={(e) => setAnchorEl(e.currentTarget)}
                          edge="end"
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          id="dropdown-menu"
                          anchorEl={anchorEl}
                          open={openAuthMenu}
                          onClose={handleCloseAuthMenu}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          disableScrollLock={true}
                        >
                          <MenuItem onClick={handleSignInOpen}>Log in</MenuItem>
                          <MenuItem onClick={handleSignUpOpen}>
                            Sign up
                          </MenuItem>
                        </Menu>
                      </>
                    ) : (
                      <Box display="flex" gap={2} maxWidth="min-content">
                        <CustomButton
                          variant="outlined"
                          onClick={() => setIsSigninOpen(true)}
                        >
                          Log in
                        </CustomButton>
                        <CustomButton
                          variant="contained"
                          onClick={() => setIsSignupOpen(true)}
                        >
                          Sign up
                        </CustomButton>
                      </Box>
                    ))}

                  {auth.user && (
                    <>
                      <Button
                        color="inherit"
                        aria-label="Account"
                        aria-controls="account-menu"
                        aria-haspopup="true"
                        onClick={(event) => {
                          handleOpenAccountMenu(event, 'account-menu');
                        }}
                      >
                        Account
                        <ExpandMoreIcon />
                      </Button>
                      <Menu
                        id="account-menu"
                        open={
                          !!(
                            menuAccountState &&
                            menuAccountState.id === 'account-menu'
                          )
                        }
                        anchorEl={menuAccountState && menuAccountState.anchor}
                        //getContentAnchorEl={null}
                        onClick={handleCloseAccountMenu}
                        onClose={handleCloseAccountMenu}
                        keepMounted={true}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        disableScrollLock={true}
                      >
                        <MenuItem
                          component={Link}
                          to={PATHS.SETTINGS('billing')}
                        >
                          Settings
                        </MenuItem>

                        <Divider />
                        <MenuItem onClick={auth.signout}>Log out</MenuItem>
                      </Menu>
                    </>
                  )}

                  <IconButton
                    color="inherit"
                    onClick={darkMode.toggle}
                    style={{ opacity: 0.6 }}
                    size="large"
                  >
                    {darkMode.value ? (
                      <DarkModeOutlinedIcon />
                    ) : (
                      <LightModeOutlinedIcon />
                    )}
                  </IconButton>
                </Box>
              </>
            )}
        </Toolbar>
      </AppBar>
      <LoginModal
        open={isSignupOpen}
        handleClose={() => setIsSignupOpen(false)}
        type="signup"
      />
      <LoginModal
        open={isSigninOpen}
        handleClose={() => setIsSigninOpen(false)}
        type="signin"
        title="Welcome back"
      />
      <WaitingListModal
        open={isWatingModalOpen}
        handleClose={() => setIsWaitingModalOpen(false)}
      />
    </>
  );
};

export default Navbar;
