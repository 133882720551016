import React from 'react';
import { Box, Typography } from '@mui/material';

const SectionHeader = ({
  subtitle,
  title,
  titleAlign = 'center',
  caption,
  size,
  subtitleSize,
  textColor,
  className,
  ...otherProps
}) => {
  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box
      sx={{ '&:not(:last-child)': { marginBottom: '2rem' } }}
      {...otherProps}
    >
      {caption && (
        <Typography
          fontWeight="bold"
          variant={`h${size + 1}`}
          color={textColor}
          gutterBottom={!!subtitle}
        >
          {caption}
        </Typography>
      )}
      {title && Array.isArray(title) ? (
        title.map((line, index) => (
          <Typography
            key={index}
            fontWeight={index === 0 && 'bold'}
            variant={`h${size}`}
            component="h3"
            color={textColor}
            gutterBottom={!!subtitle}
            textAlign={titleAlign}
          >
            {line}
          </Typography>
        ))
      ) : (
        <Typography
          variant={`h${size}`}
          component="h3"
          color={textColor}
          gutterBottom={!!subtitle}
          textAlign={titleAlign}
        >
          {title}
        </Typography>
      )}

      {subtitle && (
        <Typography
          variant={'subtitle1'}
          fontSize={subtitleSize && `${subtitleSize}px`}
          display="inline-block"
          color="primary"
          maxWidth="700px"
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default SectionHeader;
