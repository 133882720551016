import styled from '@emotion/styled';
import { Button, Link } from '@mui/material';

export const BannerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#2e99f7',
  justifyContent: 'space-between',
  padding: '14px 16px',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledLink = styled(Link)({
  color: 'white',
  textDecorationColor: 'white',
});

export const StyledTextButton = styled(Button)({
  color: 'white',
  textDecoration: 'underline',
  textDecorationColor: 'white',
});

export const StyledCloseButton = styled(Button)({
  minWidth: '0',
  '& .MuiButton-startIcon': {
    margin: 0,
  },
});
