import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Typography } from '@mui/material';
import { CHROME_EXTENSION_APP_LINK } from 'consts';

import {
  BannerWrapper,
  StyledCloseButton,
  StyledLink,
  StyledTextButton,
} from './styles';

const Banner = ({ setIsSurferOpen, setIsBannerOpen }) => {
  const handleClose = async () => {
    localStorage.removeItem('isBannerOpen');
    setIsBannerOpen(false);
  };

  return (
    <BannerWrapper>
      <Box display="flex" color="white" gap={4} alignItems="center">
        <ErrorIcon fontSize="large" sx={{ color: 'white' }} />
        <Typography>
          <strong>Get your Surfer Buddy now! Download from</strong>{' '}
          <StyledLink href={CHROME_EXTENSION_APP_LINK} target="_blank">
            Chrome Web Store
          </StyledLink>
        </Typography>
      </Box>
      <Box display="flex" gap={4}>
        <Typography color="white">
          <StyledTextButton onClick={() => setIsSurferOpen(true)}>
            View details
          </StyledTextButton>
        </Typography>
        <StyledCloseButton
          onClick={handleClose}
          startIcon={<CloseIcon sx={{ color: 'white' }} />}
        />
      </Box>
    </BannerWrapper>
  );
};

export default Banner;
