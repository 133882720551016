import React from 'react';

import {
  StyledBackgroundGradient,
  StyledBackgroundImage,
  StyledBox,
} from './styles';

const verticalPaddings = {
  normal: { xs: 6 },
  medium: { xs: 6, sm: 10 },
  large: { xs: 6, sm: 20 },
  auto: 0,
};

const Section = (props) => {
  const {
    bgColor = 'default',
    bgImage,
    bgImageOpacity,
    bgGradient,
    size = 'normal',
    className,
    children,
    bgPosition,
    bgSize,
    ...otherProps
  } = props;

  // Get MUI responsize size object based
  // on size prop (normal, medium, large, auto)
  const verticalPadding = verticalPaddings[size];

  return (
    <StyledBox
      component="section"
      bgcolor={bgColor}
      py={verticalPadding}
      {...otherProps}
    >
      {bgImage && (
        <StyledBackgroundImage
          image={bgImage}
          opacity={bgImageOpacity}
          bgPosition={bgPosition}
          bgSize={bgSize}
        />
      )}

      {bgGradient && (
        <StyledBackgroundGradient
          gradient={bgGradient}
          opacity={bgImageOpacity}
        />
      )}

      {props.children}
    </StyledBox>
  );
};

export default Section;
