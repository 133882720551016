import { ResizableBox } from 'react-resizable';
import { styled } from '@mui/material/styles';

export const ResizeBox = styled(ResizableBox)({
  position: 'relative',
  cursor: 'move',

  '& .react-resizable-handle': {
    position: 'absolute',
    width: '20px',
    height: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'content-box',
    boxSizing: 'border-box',
    backgroundImage:
      "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
    backgroundPosition: 'bottom right',
    padding: '0 3px 3px 0',
  },

  '& .react-resizable-handle-se': {
    bottom: 2,
    right: 2,
    cursor: 'se-resize',
  },
});
