import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  width: '300px',
  maxHeight: '100%',
  backgroundColor: theme.palette.modalsBackground,
  border: '1px solid black',
  boxShadow: 24,
  padding: '24px',
  textAlign: 'center',
  color: theme.palette.primary.main,
}));
