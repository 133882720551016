import { Button, Grid, Link } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

import PickerLogo from 'assets/icons/PickerLogoB';
import PickerLogoInverted from 'assets/icons/PickerLogoW';

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth',
})(({ theme, open, drawerWidth }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const CustomLink = styled(Link)({
  textDecoration: 'none',
  whiteSpace: 'nowrap',

  '&.active': {
    color: '#00bcff',
    textShadow: '0 0 2px rgba(0,137,255,0.3)',
  },
});

export const CustomGrid = styled(Grid)(({ theme }) => ({
  marginLeft: '40px',
  fontSize: '0.875rem',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  width: '100px',
  maxHeight: '36.5px',
}));

export const StyledPickerLogo = styled(PickerLogo)(({ theme }) => ({
  width: '150px',
  [theme.breakpoints.down('md')]: {
    width: '110px',
  },
}));

export const StyledPickerLogoInverted = styled(PickerLogoInverted)(
  ({ theme }) => ({
    width: '150px',
    [theme.breakpoints.down('md')]: {
      width: '110px',
    },
  })
);
