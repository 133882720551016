import { Button, Grid, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FooterWrapper = styled(Grid)({
  fontSize: '0.875rem',
  justifyContent: 'center',
});

export const CustomLink = styled(Link)({
  color: 'white',
  textDecoration: 'none',
  whiteSpace: 'nowrap',

  '&.active': {
    color: '#00bcff',
    textShadow: '0 0 2px rgba(0,137,255,0.3)',
  },
});

export const StyledIconButton = styled(Button)({
  minWidth: 0,
  padding: 0,
  '& .MuiButton-startIcon': {
    margin: 0,
  },
});

export const StyledIcon = styled('img')({
  minWidth: '0',
  padding: 0,
  cursor: 'pointer',
  '& .MuiButton-startIcon': {
    margin: 0,
  },
});
