import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@mui/material';
import { PATHS } from 'AppPaths';
import {
  facebookLinkTrack,
  instagramLinkTrack,
  linkedInTrack,
} from 'util/analyticsHandlers';

import Section from 'components/Section';

import {
  CustomLink,
  FooterWrapper,
  StyledIcon,
  StyledIconButton,
} from './styles';

import FBWhite from 'assets/icons/FBWhite.png';
import InstagramGlyphWhite from 'assets/icons/InstagramGlyphWhite.png';
import LinkedInWhite from 'assets/icons/LinkedInWhite.png';
import YTWhite from 'assets/icons/YTWhite.png';

const links = [
  { path: PATHS.FEATURES, name: 'Features' },
  { path: PATHS.PRICING, name: 'Price' },
  { path: PATHS.COMPANY, name: 'Company' },
  { path: PATHS.SUPPORT, name: 'Help & Support' },
  { path: PATHS.TERMS_AND_PRIVACY, name: 'Terms & Privacy' },
  { path: PATHS.CONTACTS, name: 'Contact us' },
  { path: PATHS.CREDITS, name: 'Credits' },
  { path: PATHS.DOWNLOAD, name: 'Download' },
];

const Footer = () => {
  return (
    <Section
      bgColor="mainPage"
      size="normal"
      bgImageOpacity={1}
      sx={{ mt: 'auto' }}
      component="footer"
      isNotPrintable
    >
      <Container>
        <FooterWrapper container>
          <Grid
            container
            item
            md
            columnSpacing={3}
            rowSpacing={1}
            justifyContent={{ xs: 'center', md: 'flex-start' }}
            alignItems="center"
            mb={{ xs: '10px', md: 0 }}
          >
            {links.map((el, index) => (
              <Grid key={index} item>
                <CustomLink component={NavLink} to={el.path}>
                  {el.name}
                </CustomLink>
              </Grid>
            ))}
          </Grid>
          <Box display="flex" gap={3} alignItems="center">
            <StyledIconButton
              href="https://www.facebook.com/profile.php?id=61555766145906"
              target="_blank"
              onClick={facebookLinkTrack}
              startIcon={
                <StyledIcon alt="Facebook" src={FBWhite} width="30px" />
              }
            />
            <StyledIconButton
              href="https://www.instagram.com/picker_hq?igsh=emU4a3Z6OXJkejI%3D&utm_source=qr"
              target="_blank"
              onClick={instagramLinkTrack}
              startIcon={
                <StyledIcon
                  alt="Instagram"
                  src={InstagramGlyphWhite}
                  width="30px"
                />
              }
            />
            <StyledIconButton
              href="https://www.linkedin.com/company/gopicker/"
              target="_blank"
              onClick={linkedInTrack}
              startIcon={
                <StyledIcon alt="LinkedIn" src={LinkedInWhite} width="30px" />
              }
            />
            <StyledIconButton
              href="https://www.youtube.com/@Picker-worksmarter"
              target="_blank"
              onClick={linkedInTrack}
              startIcon={
                <StyledIcon alt="YouTube" src={YTWhite} width="30px" />
              }
            />
            <Typography
              variant="span"
              color="white"
            >{`© ${new Date().getFullYear()} Picker US Inc.`}</Typography>
          </Box>
        </FooterWrapper>
      </Container>
    </Section>
  );
};

export default Footer;
