import React from 'react';
import { Box, CircularProgress, Container } from '@mui/material';

import Section from 'components/Section';

const PageLoader = ({ height = 350, children }) => {
  return (
    <Section bgColor="default">
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={height}
        >
          {children ? children : <CircularProgress size={32} />}
        </Box>
      </Container>
    </Section>
  );
};

export default PageLoader;
