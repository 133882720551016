import React from 'react';
import { Modal } from '@mui/material';
import { PATHS } from 'AppPaths';

import AuthBox from 'components/AuthBox';

import { StyledModalContent } from './styles';

const LoginModal = ({ open, handleClose, type, title }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <StyledModalContent>
        <AuthBox
          type={type}
          title={title}
          providers={['apple', 'google']}
          afterAuthPath={PATHS.COLLECTIONS}
          handleClose={handleClose}
          isModal
        />
      </StyledModalContent>
    </Modal>
  );
};
export default LoginModal;
