import React, { Suspense } from 'react';

import PageLoader from 'components/PageLoader';

const WithSuspense = (WrappedComponent, props) => (
  <Suspense fallback={<PageLoader />}>
    <WrappedComponent {...props} />
  </Suspense>
);

export default WithSuspense;
