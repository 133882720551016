import { forwardRef, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Alert, Snackbar } from '@mui/material';

import ChatBotWindow from '../ChatBotWindow';
import { ResizeBox } from './styles';

const ChatBotContent = forwardRef((props, ref) => {
  const { isError, handleCloseError, ...otherProps } = props;

  const [alertMessage, setAlertMessage] = useState('');

  const [isDraggerDisabled, setIsDraggerDisabled] = useState(false);
  const [isExtended, setIsExtended] = useState(false);

  const [windowSize, setWindowSize] = useState({ width: 900, height: 340 });
  const [windowPosition, setWindowPosition] = useState({ x: 100, y: 170 });

  const handleExtend = () => {
    const { innerWidth, innerHeight } = window;
    const newSize = isExtended
      ? { width: 900, height: 340 }
      : { width: innerWidth, height: innerHeight };
    const newPosition = isExtended ? { x: 100, y: 170 } : { x: 0, y: 0 };
    setWindowPosition(newPosition);
    setWindowSize(newSize);
  };

  const handleStop = (e, { x, y }) =>
    isDraggerDisabled || setWindowPosition({ x, y });

  const handleResize = (e, { size: { width, height } }) =>
    setWindowSize({ width, height });

  const propsToPass = {
    ...otherProps,
    setAlertMessage,
    handleExtend,
    isExtended,
    setIsDraggerDisabled,
  };

  useEffect(() => {
    const { innerWidth, innerHeight } = window;

    setIsExtended(
      windowSize.width >= innerWidth && windowSize.height >= innerHeight
    );
  }, [windowSize]);

  return (
    <>
      {otherProps.isFullScreen ? (
        <Draggable
          onDrag={(e) => !isDraggerDisabled && e.preventDefault()}
          position={windowPosition}
          onStop={handleStop}
          disabled={isDraggerDisabled}
        >
          <ResizeBox
            width={windowSize.width}
            height={windowSize.height}
            minConstraints={[400, 300]}
            onResize={handleResize}
            onResizeStart={() => setIsDraggerDisabled(true)}
            onResizeStop={() => setIsDraggerDisabled(false)}
          >
            <ChatBotWindow {...propsToPass} />
          </ResizeBox>
        </Draggable>
      ) : (
        <ChatBotWindow {...propsToPass} />
      )}
      <Snackbar
        open={isError}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={handleCloseError}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
});

export default ChatBotContent;
