import Box from '@mui/material/Box';
import { emphasize, styled } from '@mui/material/styles';

export const StyledBox = styled(Box, {
  shouldForwardProp: (propName) =>
    propName !== 'bgcolor' && propName !== 'isNotPrintable',
})(({ theme, bgcolor, isNotPrintable }) => {
  const colors = {
    default: theme.palette.background.default,
    light: emphasize(theme.palette.background.default, 0.03),
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    bgcolor: theme.palette.modalsBackground,
    mainPage: theme.palette.mainPage,
  };

  return {
    position: 'relative',

    // Ensure child <Container> is above background
    // image (if one is set with the bgImage prop).
    '& > .MuiContainer-root': {
      position: 'relative',
    },

    // Ensure text is legible on background
    color: colors[bgcolor]
      ? theme.palette.getContrastText(colors[bgcolor])
      : '',

    // text color and dividing border automatically based on background color.
    backgroundColor: colors[bgcolor] ? colors[bgcolor] : '',

    ...(bgcolor === 'inherit' ||
      (bgcolor === 'transparent' && {
        color: 'inherit',
      })),

    ...(bgcolor === 'transparent' && {
      backgroundColor: 'transparent',
    }),

    // Sibling selector that adds a top border if section above
    // has the same color class.
    // We use emphasize to compute border based on background color
    // and make sure it's always lightly visible.
    [`& + &`]: {
      borderTop: `1px solid ${
        colors[bgcolor] ? emphasize(colors[bgcolor], 0.09) : ''
      }`,
    },

    '@media print': {
      display: isNotPrintable && 'none',
    },
  };
});

export const StyledBackgroundImage = styled('div')(
  ({ image, opacity, bgPosition, bgSize }) => ({
    backgroundImage: image ? `url(${image})` : undefined,
    opacity: opacity,
    content: '""',
    backgroundPosition: bgPosition ? bgPosition : 'center',
    backgroundSize: bgSize ? bgSize : 'contain',
    backgroundRepeat: 'no-repeat',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    zIndex: 0,
  })
);

export const StyledBackgroundGradient = styled('div')(
  ({ gradient, opacity }) => ({
    backgroundImage: gradient ? gradient : undefined,
    opacity: opacity,
    content: '""',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    zIndex: 0,
  })
);
