import { Alert, Box, Button } from '@mui/material';
import { SEND_EMAIL_TIMEOUT } from 'consts';
import { sendEmailVerification } from 'firebase/auth';
import { useTimer } from 'hooks/useTimer';

const FormAlertMessage = ({ formAlert, user }) => {
  const { countDown, init } = useTimer(SEND_EMAIL_TIMEOUT);

  const handleResendEmail = () => {
    if (user) {
      sendEmailVerification(user).then(() => {
        const currentTime = Math.round(new Date().getTime() / 1000);
        localStorage.setItem('unblockResend', currentTime + SEND_EMAIL_TIMEOUT);
        init();
      });
    }
  };

  return (
    <Box mb={3}>
      <Alert severity={formAlert.type}>{formAlert.message}</Alert>
      {formAlert.code === 401 && (
        <Box textAlign="center">
          <Button
            margin="0 auto"
            onClick={handleResendEmail}
            disabled={countDown !== 0}
          >
            {countDown !== 0
              ? `Wait ${countDown} ${countDown === 1 ? 'second' : 'seconds'}`
              : 'Resend email verification'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FormAlertMessage;
