import { atom } from 'jotai';

export const collectionsAtom = atom([]);

export const faqAtom = atom([]);

export const cardTypeAtom = atom(true);

export const mapImgAtom = atom();

export const isDrawerOpenAtom = atom(false);

export const isVerificationOpenAtom = atom(false);

export const isWatingModalOpenAtom = atom(false);

export const isBannerOpenAtom = atom(false);

export const headerHeightAtom = atom(0);

export const tryForFreeEmailAtom = atom('');

export const subInfoAtom = atom({});

export const isSuccesPayModalOpenAtom = atom(false);

//Derived atoms below
/*
const truncateString = (string, maxLength = 20) => {
  if (!string) return null;
  if (string.length <= maxLength) return string;
  return string.substring(0, maxLength);
};

export const truncatedHighlightsAtom = atom((get) =>
  get(highlightsAtom).map((obj) => {
    return {
      color: obj.color,
      highlight: truncateString(obj.highlight),
      url: obj.url,
    };
  })
); */
