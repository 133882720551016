import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

import { firebaseApp } from './firebase';

//Use Server
const functions = getFunctions(firebaseApp);

//Use emulator
/* const storage = getStorage();
connectStorageEmulator(storage, 'localhost', 9199);
const functions = getFunctions(firebaseApp);
connectFunctionsEmulator(functions, 'localhost', 5001); */

export const getPdfUrl = httpsCallable(functions, 'getPdfUrl');

export const emailSender = httpsCallable(functions, 'emailSender');

export const sendWaitingListEmail = httpsCallable(
  functions,
  'sendWaitingListEmail'
);

export const sendContactUsEmail = httpsCallable(
  functions,
  'sendContactUsEmail'
);

// Gets a RevenueCat Offering
export const getRCOffering = httpsCallable(functions, 'getRCOffering');

// Gets a RevenueCat customer, it will return all customer information and subscriptions
export const getRCCustomer = httpsCallable(functions, 'getRCCustomer');

// Gets a valid referralCode from the referralCodes collection, or null
export const getValidReferralCode = httpsCallable(
  functions,
  'getValidReferralCode'
);

// Gets Stripe available products for the user
export const getStripeProducts = httpsCallable(functions, 'getStripeProducts');

// Creates a new Stripe Checkout session so the user can purchase a plan
export const createStripeCheckoutSession = httpsCallable(
  functions,
  'createStripeCheckoutSession'
);

// Creates a Stripe Customer Portal session so user can manage/cancel his subscription
export const createStripeCustomerPortalSession = httpsCallable(
  functions,
  'createStripeCustomerPortalSession'
);

export const createToken = httpsCallable(functions, 'createToken');
