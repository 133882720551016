import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'margin' && prop !== 'width',
})(({ margin, width }) => ({
  borderRadius: '30px',
  color: 'white',
  padding: '10px 20px',
  backgroundColor: '#09006e',
  margin: margin,
  width: width,
  '& .MuiIcon-root': {
    display: 'flex',
  },
  '&:hover': {
    backgroundColor: '#1300d6',
  },
}));
