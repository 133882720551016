import React, { useEffect, useState } from 'react';
import AppleIcon from '@mui/icons-material/Apple';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from 'util/auth';

import { LastUsedIndicator, StyledButton, StyledIcon } from './styles';

import GoogleIcon from 'assets/icons/googleIcon.svg';

const providerDisplayNames = {
  google: 'Google',
  apple: 'Apple',
};

const AuthSocial = (props) => {
  const auth = useAuth();

  const [pending, setPending] = useState(null);
  const [lastUsed, setLastUsed] = useState(null);

  const onSigninWithProvider = (provider) => {
    setPending(provider);
    auth
      .signinWithProvider(provider)
      .then((user) => {
        localStorage.setItem('lastUsedAuthProvider', provider);
        if (props.handleClose) props.handleClose();

        if (props.onAuth) props.onAuth(user);
      })
      .catch((error) => {
        setPending(null);
        props.onError(error.message);
      });
  };

  // Get value of last used auth provider
  useEffect(() => {
    if (props.showLastUsed) {
      const lastUsed = window.localStorage.getItem('lastUsedAuthProvider');
      if (lastUsed) {
        setLastUsed(lastUsed);
      }
    }
  }, [props.showLastUsed]);

  return (
    <div>
      {props.providers.map((provider) => (
        <StyledButton
          variant="outlined"
          size="large"
          type="submit"
          // TJO GOOGLE
          disabled={pending === provider}
          fullWidth
          onClick={() => {
            onSigninWithProvider(provider);
          }}
          key={provider}
        >
          <StyledIcon>
            {provider === 'apple' && <AppleIcon />}
            {provider === 'google' && (
              <img src={GoogleIcon} alt={providerDisplayNames[provider]} />
            )}
          </StyledIcon>

          {pending === provider ? (
            <CircularProgress size={28} />
          ) : (
            <span>
              {props.buttonAction} with {providerDisplayNames[provider]}
            </span>
          )}

          {provider === lastUsed && (
            <LastUsedIndicator>Last used</LastUsedIndicator>
          )}
        </StyledButton>
      ))}
    </div>
  );
};

export default AuthSocial;
