import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(Button)(({ theme }) => ({
  position: 'relative',

  '&:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}));

export const StyledIcon = styled('div')({
  position: 'absolute',
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
  width: '1.5em',
  height: '1.5em',
  left: '0.5em',
  '& > img': {
    display: 'block',
    width: '100%',
  },
});

export const LastUsedIndicator = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '-6px',
  right: '-6px',
  fontSize: '13px',
  lineHeight: 1,
  textTransform: 'initial',
  padding: '3px 5px',
  borderRadius: '7px',
  color: 'white',
  backgroundColor: theme.palette.warning.main,
  opacity: 0.8,
}));
