import { Box, Button, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BannerContainer = styled(Box)({
  position: 'fixed',
  width: '100%',
  bottom: '0',
  backgroundColor: '#e1e1e1',
  color: 'black',
  padding: '30px 0',
  zIndex: 9999,
});

export const AcceptButton = styled(Button)({
  borderRadius: '20px',
  color: 'white',
  backgroundColor: 'black',
  padding: '7px 30px',
  width: '200px',
  '&.MuiButton-root:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
});

export const SettingsButton = styled(Button)({
  color: 'black',
  padding: '7px 30px',
  width: '200px',
});

export const StyledLink = styled(Link)({
  display: 'inline-block',
  textDecoration: 'none',
  color: '#00cd00',
});
