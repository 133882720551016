import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'margin' &&
    prop !== 'bgColor' &&
    prop !== 'color' &&
    prop !== 'width' &&
    prop !== 'bgHoverColor',
})(({ margin, bgColor, color, width, bgHoverColor, fontsize, height }) => ({
  width,
  margin,
  color,
  fontsize,
  height,
  backgroundColor: bgColor,
  display: 'flex',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: bgHoverColor,
  },
}));
