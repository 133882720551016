import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Modal, OutlinedInput, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PATHS } from 'AppPaths';
import { addToWaitingList } from 'util/db';
import { sendWaitingListEmail } from 'util/firebasefunctions';
import { Link } from 'util/router';

import {
  StyledCircularProgress,
  StyledLink,
  StyledModalContent,
} from './styles';

const WaitingListModal = ({ open, handleClose }) => {
  const [pending, setPending] = useState(false);
  const [isSuccessed, setIsSuccessed] = useState(false);

  const theme = useTheme();

  const { register, handleSubmit } = useForm();

  const handleModalClose = () => {
    handleClose();
    setIsSuccessed(false);
  };

  const onSubmit = async (data) => {
    setPending(true);
    try {
      await addToWaitingList({
        firstname: data.name,
        lastname: data.lastName,
        email: data.email,
      });
      await sendWaitingListEmail({
        name: data.name,
        lastName: data.lastName,
        email: data.email,
      });
      setIsSuccessed(true);
      setPending(false);
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <StyledModalContent color={theme.palette.primary.main}>
        {!isSuccessed ? (
          <>
            <Typography variant="h4">Hello and welcome</Typography>
            <Typography fontWeight="bold">
              We’re happy you’re interested in Picker.
            </Typography>
            <Typography>
              Right now we’re BETA testing and{' '}
              <strong>by joining our waiting list</strong> you’ll{' '}
              <strong>be first in line </strong>
              to get access to a great productivity companion.
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display="flex" flexDirection="column" gap={2}>
                <OutlinedInput
                  placeholder="First name..."
                  {...register('name', {
                    required: 'Please enter your name',
                  })}
                />
                <OutlinedInput
                  placeholder="Last name..."
                  {...register('lastName')}
                />
                <OutlinedInput
                  type="email"
                  placeholder="Email..."
                  {...register('email', {
                    required: 'Please enter your email',
                  })}
                />

                <Button variant="contained" type="submit">
                  {pending ? (
                    <StyledCircularProgress size={28} />
                  ) : (
                    'Join Waiting List'
                  )}
                </Button>
              </Box>
            </form>
            <Typography
              textAlign="center"
              color="grey"
              component="p"
              variant="subtitle2"
              py={3}
            >
              By continuing you agree to Pickers{' '}
              <StyledLink
                component={Link}
                to={PATHS.TERMS_AND_PRIVACY}
                onClick={handleClose}
                color="grey"
              >
                Terms and Services
              </StyledLink>
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h4">
              Thank you so much for joining our waiting list.
            </Typography>
            <Typography variant="subtitle1">
              You will get an invite to our pre-launch via email as soon as
              possible.
            </Typography>
            <div>
              <Typography fontWeight="bold">
                Take care and see you soon.
              </Typography>
              <Typography fontWeight="bold">Best regards,</Typography>
              <Typography fontWeight="bold">Your picker team</Typography>
            </div>
          </>
        )}
      </StyledModalContent>
    </Modal>
  );
};

export default WaitingListModal;
