import { Box, Typography } from '@mui/material';

import { StyledHr } from './styles';

const DividingLine = () => {
  return (
    <Box display="flex" alignItems="center" my={2}>
      <StyledHr />
      <Typography px={1}>OR</Typography>
      <StyledHr />
    </Box>
  );
};

export default DividingLine;
