import { Icon } from '@mui/material';
import { CHROME_EXTENSION_APP_LINK } from 'consts';
import { downloadExtensionTrack } from 'util/analyticsHandlers';

import { StyledButton } from './styles';

import chromeIcon from 'assets/icons/chrome_logo.svg';

const ChromeButton = (props) => {
  return (
    <StyledButton
      variant="contained"
      href={CHROME_EXTENSION_APP_LINK}
      target="_blank"
      onClick={downloadExtensionTrack}
      startIcon={
        <Icon>
          <img src={chromeIcon} alt="goggle" />
        </Icon>
      }
      {...props}
    >
      Get Picker for Chrome
    </StyledButton>
  );
};

export default ChromeButton;
