import React from 'react';
import { Modal, Typography } from '@mui/material';

import * as ST from './styles';

import ChromeButton from 'ui/ChromeButton';

const SurferBuddyModal = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <ST.StyledModalContent>
        <Typography fontWeight="bold">Add the Surfer Buddy!</Typography>
        <Typography mb={2}>(Google Chrome extension*)</Typography>
        <Typography>
          Our productivity companion that allows you to interact with pages
          across the web.
        </Typography>
        <ChromeButton width="220px" margin="20px auto" />
        <Typography fontStyle="italic" fontSize={13} color="textSecondary">
          * Extensions are small software programs that customize the browsing
          experience.
        </Typography>
      </ST.StyledModalContent>
    </Modal>
  );
};
export default SurferBuddyModal;
