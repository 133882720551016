import { Box, CircularProgress, Link as LinkMui } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  width: '460px',
  maxHeight: '100%',
  backgroundColor: theme.palette.modalsBackground,
  border: '1px solid black',
  boxShadow: 24,
  padding: '40px',
  gap: 20,

  [theme.breakpoints.down('sm')]: {
    width: '360px',
  },
}));

export const StyledCircularProgress = styled(CircularProgress)({
  color: 'grey',
});

export const StyledLink = styled(LinkMui, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => ({
  color: color,
}));
