import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StyledModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '84px',
  right: '80px',
  width: '460px',
  backgroundColor: theme.palette.modalsBackground,
  border: '1px solid black',
  padding: `30px 54px 20px`,

  [theme.breakpoints.down('sm')]: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '360px',
  },
}));
