import analytics from './analytics';

export const downloadExtensionTrack = () => {
  analytics.track('download_extension_click');
};

export const tryForFreeTrack = () => {
  analytics.track('try_for_free_click');
};

export const facebookLinkTrack = () => {
  analytics.track('facebook_link_click');
};

export const instagramLinkTrack = () => {
  analytics.track('instagram_link_click');
};

export const linkedInTrack = () => {
  analytics.track('linked_in_click');
};

export const aiBotRequestTrack = () => {
  analytics.track('ai_bot_request_track');
};
