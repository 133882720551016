import { deleteObject, getStorage, listAll, ref } from 'firebase/storage';

const storage = getStorage();

export const getDataUrlFromFile = (file) =>
  new Promise((resolve) => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(file);
  });

export const getBlobFromDataUrl = (dataUrl) => {
  try {
    const byteString = atob(dataUrl.split(',')[1]);
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const bytesArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      bytesArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  } catch (e) {
    console.warn(e);
  }
};

export const uploadDataUrlFile = async (dataUrl, path, fileName, token) => {
  try {
    const file = getBlobFromDataUrl(dataUrl);

    const result = await fetch(
      `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o?name=${path}/${fileName}`,
      {
        body: file,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    try {
      const res = await result.json();
      return res;
    } catch (error) {
      throw new Error(`Error while parsing as JSON: ${error}`);
    }
  } catch (error) {
    return { status: 'error', code: error.code };
  }
};

export const deleteFreezedImgs = async (uuid, pageId) => {
  const folderRef = ref(storage, `offlinePages/${uuid}/${pageId}`);
  const fileList = await listAll(folderRef);
  const promises = [];

  for (let item of fileList.items) {
    promises.push(deleteObject(item));
  }

  const result = await Promise.all(promises);
  return result;
};

export const getImageSrc = (mapImg, title) => {
  const result = title ? title.charAt(0).toUpperCase() : '?';
  let img;
  mapImg[result] ? (img = mapImg[result]) : (img = mapImg['UFO']);
  return img;
};

export const isImgUrl = (url) => {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
};
