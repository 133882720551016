import React, { useState } from 'react';
import CookieBanner from 'react-cookie-banner';
import { Box, CssBaseline } from '@mui/material';
import { useAtom } from 'jotai';
import Drawer from 'layout/Drawer';
import Footer from 'layout/Footer';
import Navbar from 'layout/Navbar';
import VerificationModal from 'layout/VerificationModal';
import Routes from 'routes';
import {
  headerHeightAtom,
  isDrawerOpenAtom,
  isVerificationOpenAtom,
} from 'store';
import { AuthProvider } from 'util/auth';
import { QueryClientProvider } from 'util/db';
import MetaPixel from 'util/meta/metaPixel';
import Rewardful from 'util/rewardful/rewardful';
import { Router } from 'util/router';
import { ThemeProvider } from 'util/theme';

import CustomCookieBanner from 'components/CustomCookieBanner/CustomCookieBanner';
import DrawerHeader from 'components/DrawerHeader';

import SurferBuddyModal from './Banner/SurferBuddyModal';
import { Main } from './styles';

const drawerWidth = 240;

const App = () => {
  const [isSurferOpen, setIsSurferOpen] = useState(false);

  const [isVerificationOpen, setIsVerificationOpen] = useAtom(
    isVerificationOpenAtom
  );
  const [isDrawerOpen, setIsDrawerOpen] = useAtom(isDrawerOpenAtom);
  const [headerHeight] = useAtom(headerHeightAtom);

  const ToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <QueryClientProvider>
      <ThemeProvider>
        <AuthProvider>
          <MetaPixel />
          <Rewardful />
          <Router>
            <>
              <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar
                  color="default"
                  open={isDrawerOpen}
                  ToggleDrawer={ToggleDrawer}
                  drawerWidth={drawerWidth}
                  setIsSurferOpen={setIsSurferOpen}
                />
                <Drawer
                  open={isDrawerOpen}
                  ToggleDrawer={ToggleDrawer}
                  drawerWidth={drawerWidth}
                />
                <Main open={isDrawerOpen} drawerWidth={drawerWidth}>
                  <DrawerHeader height={headerHeight} />
                  <Routes />
                  <Footer />
                  <CookieBanner dismissOnScroll={false} dismissOnClick={false}>
                    {(onAccept) => <CustomCookieBanner onAccept={onAccept} />}
                  </CookieBanner>
                  <VerificationModal
                    open={isVerificationOpen}
                    handleClose={() => setIsVerificationOpen(false)}
                  />
                  <SurferBuddyModal
                    open={isSurferOpen}
                    handleClose={() => setIsSurferOpen(false)}
                  />
                </Main>
              </Box>
            </>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
